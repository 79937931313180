import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Grid, InputLabel, TextField, Typography } from '@mui/material';
import axios, { Axios } from 'axios';
import { useTheme } from '@mui/material/styles';
import mockData from '../_mock/mockData';
import MyCard from './MyCard';
import TradeSentiments from './TradeSentiments';
import Authentication from './Authentication';

const api = axios.create({
    baseURL: 'https://webtrader.milescap.com', // Replace with the actual base URL
});
const RiskGuide = () => {
    const theme = useTheme()
    const { goldRate, askPrice, bidPrice, lowPrice, highPrice } = mockData.goldSpotRate;
 


    // const axiosInstance = Axios.create({
    //     baseURL: 'https://webtrader.milescap.com/api/auth/start?version=4000&agent=1017t&login=1017&type=WebManager', // Replace with the actual base URL
    //     timeout: 5000, // Set a timeout value as needed
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   });

    // const authenticateAndFetchTickData = () => {
    //   // Authenticate on the server using the Auth command
    //   const authData = {
    //     login: 1017,
    //     password: 'Miles#2023@1023',
    //     build: 484,
    //     agent: 'WebManager',
    //   };

    //   fetch('https://webtrader.milescap.com/api/auth/start?version=' + authData.build + '&agent=' + authData.agent + '&login=' + authData.login + '&type=WebManager', {
    //     method: 'GET',
    //   })
    //     .then((response) => response.json())
    //     .then((authStartAnswer) => {
    //       if (authStartAnswer.retcode === 0) {
    //         // Authentication successful, now fetch tick data
    //         fetchTickData();
    //       } else {
    //         console.error('Authentication failed: ' + authStartAnswer.retcode);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Authentication error:', error);
    //     });
    // };

    // const fetchTickData = () => {
    //   // Request the latest tick data for the symbol XAUUSD
    //   fetch('https://webtrader.milescap.com/api/tick/last?symbol=XAUUSD&trans_id=0', {
    //     method: 'GET',
    //   })
    //     .then((response) => response.json())
    //     .then((tickData) => {
    //       if (tickData.retcode === 0) {
    //         setQuoteData(tickData.answer[0]); // Assuming the response is an array of quotes
    //       } else {
    //         console.error('Tick data fetch failed: ' + tickData.retcode);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Tick data fetch error:', error);
    //     });
    // };


    // useEffect(() => {

    // }, []);
    const cardStyle = {
        background: "#161511",
        padding: 1,
        minHeight: "300px"
    }
    const spanStyle = {
        backgroundColor: theme.palette.background.default,
        borderRadius: 5, textAlign: "center",
        padding: 6, minWidth: "110px",
        border: "1px solid #cfb97d"
    }; const spanStyle2 = {
        backgroundColor: theme.palette.background.default,
        borderRadius: 5, textAlign: "center",
        padding: 6, minWidth: "80px",
        border: "1px solid #cfb97d"
    };
    return (<Grid container spacing={1}  >
        {/* <Authentication/> */}
        <Grid item md={12} lg={12}  >
            <MyCard sx={{ mt: 0, background: theme.palette.background.default, border: "1px solid #303028" }}>
                <Typography variant="h6"
                    sx={{
                        background: "#cfb97d",
                        color: theme.palette.text.third,
                        padding: 1, borderRadius: "12px"
                    }}
                    textAlign={"center"}
                >
                    LOW RISK GOLD TT BAR GUIDNESS
                </Typography>
                <CardContent sx={{
                    background: theme.palette.background.secondary,
                    padding: 1,
                }}>
                    <Grid container spacing={1} p={1} >
                        <Grid item md={6} lg={6}  >
                            <Card sx={{ ...cardStyle, textAlign: 'center' }}>
                                <Typography mb={1.5} fontWeight={700}> DEPOSIT </Typography>
                                <Grid container item spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item md={4} lg={4} mb={1.5} >
                                        <Typography variant="body2" style={spanStyle}   >  1000 AED     </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item md={4} lg={4} mb={1.5}>
                                        <Typography variant="body2" style={spanStyle}   >  2500 AED     </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item md={4} lg={4} mb={1.5}>
                                        <Typography variant="body2" style={spanStyle}   > 5000 AED     </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item md={4} lg={4} mb={1.5}>
                                        <Typography variant="body2" style={spanStyle}   >  10,000 AED     </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item md={4} lg={4} mb={.5} textAlign="center">
                                        <Typography variant="body2" style={spanStyle}   >25,000 AED</Typography>
                                    </Grid>
                                </Grid>
                            </Card>


                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Card sx={{ ...cardStyle, textAlign: 'center' }}>
                                <Typography mb={1.5} fontWeight={700}> No. of TT Bar </Typography>
                                <Grid container item spacing={2} alignItems="center" justifyContent="center" mb={1.5}>
                                    <Grid item md={3} lg={3} style={{ textAlign: 'center' }}>
                                        <Typography variant="body2" style={spanStyle2}   >0</Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography> To </Typography>
                                    </Grid>
                                    <Grid item md={3} lg={3}>
                                        <Typography variant="body2" style={spanStyle2}   >1</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2} alignItems="center" justifyContent="center" mb={1.5}>
                                    <Grid item md={3} lg={3}>
                                        <Typography variant="body2" style={spanStyle2}   >1</Typography>
                                    </Grid>
                                    <Grid item  >
                                        <Typography> To </Typography>
                                    </Grid>
                                    <Grid item md={3} lg={3}>
                                        <Typography variant="body2" style={spanStyle2}   >2</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2} alignItems="center" justifyContent="center" mb={1.5}>
                                    <Grid item md={3} lg={3}>
                                        <Typography variant="body2" style={spanStyle2}   >2</Typography>
                                    </Grid>
                                    <Grid item  >
                                        <Typography> To </Typography>
                                    </Grid>
                                    <Grid item md={3} lg={3}>
                                        <Typography variant="body2" style={spanStyle2}   >3</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2} alignItems="center" justifyContent="center" mb={1.5}>
                                    <Grid item md={3} lg={3}>
                                        <Typography variant="body2" style={spanStyle2}   >4</Typography>
                                    </Grid>
                                    <Grid item  >
                                        <Typography> To </Typography>
                                    </Grid>
                                    <Grid item md={3} lg={3}>
                                        <Typography variant="body2" style={spanStyle2}   >6</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2} alignItems="center" justifyContent="center" mb={.5}>
                                    <Grid item md={3} lg={3}>
                                        <Typography variant="body2" style={spanStyle2}   >10</Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography> To </Typography>
                                    </Grid>
                                    <Grid item md={3} lg={3}>
                                        <Typography variant="body2" style={spanStyle2}   >15</Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item md={12} lg={12} textAlign="center">
                            <Box display="flex" alignItems="center" sx={{
                                paddingTop: "5px",
                                borderTop: "1px solid #e1af2b",
                                marginTop: "5px"
                            }} justifyContent="center">
                                <Typography variant="body2" sx={{
                                    fontSize: "10px",
                                    color: theme.palette.text.secondary,
                                }} textAlign="center">
                                    Powered By:
                                </Typography> <Box component="img" src="/assets/miles-logo.png" sx={{ height: "25px" }} />

                            </Box>
                        </Grid>
                    </Grid>

                </CardContent >
            </MyCard >
        </Grid>
        <Grid item md={12} lg={12} mt={1}>
            <MyCard  >
                <Typography variant="caption" fontStyle={"italic"} textAlign={"center"}
                >   *TT BAR = 124.412  </Typography>

            </MyCard>
        </Grid>

    </Grid>
    );
};

export default RiskGuide;
