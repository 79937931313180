import { Container } from '@mui/material'; 
import {   useTheme } from '@mui/material/styles';
import useResponsive from '../hooks/useResponsive';

const MyContainer = ({ children }) => {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  return (
    <Container
      sx={{
        backgroundColor: theme.palette.background.default,
        // borderRadius: '10px',
        // boxShadow: '3px 4px 12px 0px rgba(0, 0, 0, 0.1)',/
        padding: 2,
        paddingTop:mdUp? 4:1,
        width:"100%",
        maxWidth:"unset !important"
      }}
    >
      {children}
    </Container>
  );
};

export default MyContainer;