import { Navigate, useRoutes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import NotifyCall from './pages/NotifyCall';
import Risk from './pages/RiskGuidePage';


// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <HomePage />,
    }, 
    // {
    //   path: '/Noti',
    //   element: <NotifyCall />, 
    // },
    {
      path: '/Notificationloonagoldexternal',
      element: <NotifyCall />, 
    },
    // {
    //   path: '*',
    //   element: <Navigate to="/" replace />,
    // },
  ]);

  return routes;
}
