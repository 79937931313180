import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import mockData from '../_mock/mockData';
import MyCard from './MyCard';
import { fetchData } from '../api';
import { selectData } from '../dataSlice';
 
import Sentimentcharts from './SentimentCharts';

const SpotRateComponent = ({ type }) => {
    const theme = useTheme()
    const dispatch = useDispatch();
    const data = useSelector(selectData);
 
    const [fontSilverColor, setFontSilverColor] = useState('#fff');
    const [fontColors, setFontColor] = useState('#fff');
    const [prvBid, setprvBid] = useState(0);
    const [prvSilverBid, setprvSilverBid] = useState(0);

        // Inside the SpotRateComponent component
    useEffect(() => {
        fetchData(dispatch);
        const dataIntervalId = setInterval(() => {
            fetchData(dispatch);
        }, 3850); // 10 seconds
        return () => {
            clearInterval(dataIntervalId);
        };
    }, [dispatch]);

 

    if (data) {
        if (prvBid !== data[0].Ask) {
            if (prvBid >= data[0].Ask) {
                setFontColor('#ff4337')
            } else {
                setFontColor('#1ED270')
            }
            setprvBid(data[0].Ask);
            setTimeout(() => {
                setFontColor("#fff")
            }, 200);
        }
        if (prvSilverBid !== data[1].Ask) {
            if (prvSilverBid >= data[1].Ask) {
                setFontSilverColor('#ff4337')
            } else {
                setFontSilverColor('#1ED270')
            }

            setprvSilverBid(data[1].Ask);
            setTimeout(() => {
                setFontSilverColor("#fff")
            }, 200);
        }
      
    }

   
    

    return (
        <MyCard sx={{ mt: 0, background: theme.palette.background.default }}>
            <Typography variant="h6"
                sx={{
                    background: type === "GOLD" ? theme.palette.background.gold : theme.palette.background.silver,
                    color: theme.palette.text.third,
                    padding: 1,
                }}
                textAlign={"center"}
            >
                {type} Oz
            </Typography>
            <CardContent sx={{
                background: theme.palette.background.secondary,
                padding: 1,
            }}>
                {(!data) ?
                <Box style={{padding:"75px",justifyContent:"center",display:"flex"}}>
                    <Box
                        component="img" width={"50px"}
                        src="/assets/loader.gif"
                    /> </Box>:
                    <Grid container spacing={1}   > {/* Removed spacing */}
                        <Grid item xs={6} md={6} lg={6}>
                            <Card sx={{
                                background: theme.palette.background.default
                            }}>
                                <Typography variant="button" fullWidth
                                    textAlign={"center"}
                                    sx={{
                                        background: theme.palette.background.third,
                                        p: 0.25, fontSize: 13,
                                        display: 'block'

                                    }} gutterBottom>
                                    BUY
                                </Typography>
                                <Typography variant="h6" textAlign={"center"} color={type === "GOLD" ? fontColors : fontSilverColor}  >
                                    {type === "GOLD" ? data[0].Ask : data[1].Ask}
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Box
                                        sx={{
                                            width: '21px',
                                            height: '21px',
                                            borderRadius: "3px",
                                            margin: 1,
                                            background: "#F44336",
                                            svg: {
                                                width: '11px',
                                                height: '12px',
                                            },
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                                            <path d="M3.20831 3.5L7.79165 8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.20831 8.5L7.79165 8.5L7.79165 3.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </Box>
                                    <Box
                                        sx={{

                                            height: '21px',
                                            borderRadius: "3px",
                                            margin: 1, padding: 0.5,
                                            background: "#434343",
                                        }}
                                    >
                                        <Typography sx={{
                                            color: "#FFF",
                                            // fontfamily: "Poppins",
                                            fontSize: 9
                                        }}>
                                            {` LOW ${type === "GOLD" ? data[0].BidLow : data[1].BidLow}`}
                                        </Typography>
                                    </Box>

                                </div>
                            </Card>
                        </Grid>


                        <Grid item xs={6} md={6} lg={6}>
                            <Card sx={{
                                background: theme.palette.background.default
                            }} >
                                <Typography variant="button"
                                    sx={{
                                        background: theme.palette.background.third,
                                        p: 0.25, fontSize: 13, display: 'block'
                                    }}
                                    textAlign={"center"} gutterBottom>
                                    SELL
                                </Typography>
                                <Typography variant="h6" textAlign={"center"} color={type === "GOLD" ? fontColors : fontSilverColor}   >
                                    {type === "GOLD" ? data[0].Bid : data[1].Bid}
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Box
                                        sx={{
                                            width: '21px',
                                            height: '21px',
                                            borderRadius: "3px",
                                            margin: 1,
                                            background: "#1ED270",
                                            svg: {
                                                width: '11px',
                                                height: '12px',
                                            },
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                                            <path d="M3.20834 8.5L7.79168 3.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7.79168 8.5L7.79168 3.5L3.20834 3.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </Box>
                                    <Box
                                        sx={{

                                            height: '21px',
                                            borderRadius: "3px",
                                            margin: 1, padding: 0.5,
                                            background: "#434343",
                                        }}
                                    >
                                        <Typography sx={{
                                            color: "#FFF",
                                            // fontfamily: "Poppins",
                                            fontSize: 9
                                        }}>
                                            {`HIGH ${type === "GOLD" ? data[0].BidHigh : data[1].BidHigh}`}
                                        </Typography>
                                    </Box>

                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sx={{
                            display: 'flex',
                            justifyContent: "center",
                            flexWrap: "wrap",

                        }}  >
                                <Sentimentcharts type={type} />
                            
                        </Grid>
                    </Grid>
                }

            </CardContent>
        </MyCard >
    );
};

export default SpotRateComponent;
