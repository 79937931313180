// FirebaseService.js
// import { getFirestore, collection, getDocs,getDoc, doc, updateDoc } from 'firebase/firestore';
// import { initializeApp } from 'firebase/app';
const {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
} = require('firebase/firestore');

const { initializeApp } = require('firebase/app');

const firebaseConfig = {
  apiKey: "AIzaSyBN1l1j-pO3OoLlcukLSMATb7oO6Ee5aks",
  authDomain: "loona-gold.firebaseapp.com",
  projectId: "loona-gold",
  storageBucket: "loona-gold.appspot.com",
  messagingSenderId: "205946326272",
  appId: "1:205946326272:web:0813a53dd6df5ebd4637e5",
  measurementId: "G-0RLQM7PVG2"
};

const initializeFirebase = () => {
  const app = initializeApp(firebaseConfig);
  return getFirestore(app);
};

const fetchDataFromFirestore = async () => {
  try {
    const db = initializeFirebase();
    const collectionRef = collection(db, 'loonapricealert');
    const snapshot = await getDocs(collectionRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

const updateDocumentInFirestore = async (item) => {
  try {
    // const { setPriceValue, currentPrice,fcmToken } = item;
    // const db = initializeFirebase();

    // const docRef =await doc(db, 'loonapricealert', fcmToken);
    // const docSnapshot = await getDoc(docRef)

    // const documentRef =await doc(collection(db, 'loonapricealert'), fcmToken);
    // // console.log("itemCOntent",docSnapshot.data().alert.filter((item, i) => item.setPriceValue !== setPriceValue) )
    // const existingAlerts = docSnapshot.exists ? docSnapshot.data().alert.filter((item, i) => item.setPriceValue !== setPriceValue) : [];
    // const docSnap = await updateDoc(documentRef, { alert: existingAlerts });

    // console.log('Document updated successfully');

    const { setPriceValue, currentPrice, fcmToken } = item;
    const db = initializeFirebase();

    const docRef = await doc(db, 'loonapricealert', fcmToken);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists) {
      const existingAlerts = docSnapshot.data().alert;
      const updatedAlerts = existingAlerts.filter((item, i) => item.setPriceValue !== setPriceValue);

      if (updatedAlerts.length === 0) {
        // If no alerts left, delete the document
        await deleteDoc(docRef);
        console.log('Document deleted successfully');
      } else if (updatedAlerts.length !== existingAlerts.length) {
        // If there are remaining alerts, and the array is updated, update the document
        const documentRef = await doc(db, 'loonapricealert', fcmToken);
        await updateDoc(documentRef, { alert: updatedAlerts });
        console.log('Document updated successfully');
      } else {
        console.log('No changes needed');
      }
    } else {
      console.log('Document does not exist');
    }

  } catch (error) {
    console.error('Error updating document:', error);
  }
};

module.exports = {
  fetchDataFromFirestore,
  updateDocumentInFirestore
  // Other exports...
};