// dataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
  name: 'data',
  initialState: null,
  reducers: {
    setData: (state, action) => {
      return action.payload;
    }, 
  },
});

export const { setData } = dataSlice.actions;

// Add this export
export const selectData = (state) => state.data;

export default dataSlice.reducer;
