
import { useEffect } from 'react';
import { fetchDataforNotify } from '../api';
import MyContainer from '../components/MyContainer';

export default function NotifyCall() {
  // Inside the SpotRateComponent component


  // useEffect(() => {
 
      fetchDataforNotify(); 
  // }, []);

  return (
    <>


      <MyContainer>

        Notification Callss

      </MyContainer>
    </>
  );
}
