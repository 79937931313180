import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { PieChart, Pie, Cell, Text } from 'recharts';

const RADIAN = Math.PI / 180;

let cx = 125; // Adjusted center x-coordinate
let cy = 55; // Adjusted center y-coordinate
let iR = 50;
let oR = 60;

const TradeSentiments = ({ type = 1, percent = 3.244, buy = 20, sell = 80 }) => {
  const data = [
    { name: 'A', value: buy, color: '#1EE77A' },
    { name: 'B', value: sell, color: '#F44336' },
  ];
  const value = useState(percent); // Replace with your actual value
  if (type === 2) {
    cx = 55; // Adjusted center x-coordinate
    cy = 55; // Adjusted center y-coordinate
    iR = 50;
    oR = 60;
  }
  return (
    <Box textAlign={"center"} sx={{
      display: 'flex', width: "100%", justifyContent: "center", flexWrap: "wrap"
    }} mb={1}>
      <Box textAlign={"center"} sx={{
        display: 'flex', width: "100%", justifyContent: "center", flexWrap: "wrap"
      }} mb={1}>
        <PieChart width={125} height={58}> {/* Adjusted the height */}
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            fill="#8884d8"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {/* <Text x={cx} y={cy + 10} textAnchor="middle" verticalAnchor="middle" fill="#d0d000" fontSize={16}>
          {`+3.27777%`}
        </Text> */}
        </PieChart>
      </Box>
      {percent && <Typography fullWidth textAlign={"center"} sx={{
        fontSize: "15px",
        mt: "-20px", display: 'block'
      }} variant="caption" color={"#fff"}>
        {percent >= 0 ? `+${percent}%` : `${percent}%`}
      </Typography>
      }
    </Box>
  );
};

export default TradeSentiments;
