import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useResponsive from '../hooks/useResponsive';
import MyCard from './MyCard';


const calculateTimeRemaining = () => {
  const currentTime = new Date();

  // Check if it's Saturday or Sunday
  if (currentTime.getDay() === 0 || currentTime.getDay() === 6) {
    return { isInactive: true };
  }

  // Set the target time to 1:30 AM and 2:30 AM in UTC time
  const targetTime1 = new Date(currentTime);
  targetTime1.setHours(1, 30, 0, 0);

  const targetTime2 = new Date(currentTime);
  targetTime2.setHours(2, 30, 0, 0);

  if (currentTime >= targetTime1 && currentTime < targetTime2) {
    return { isInactive: true };
  }

  const targetTime = targetTime1;

  if (currentTime >= targetTime2) {
    // If the current time is after 2:30 AM, use the next day's 1:30 AM
    targetTime.setDate(targetTime.getDate() + 1);
  }

  const timeDifference = targetTime - currentTime; // Calculate the time difference in milliseconds

  const hoursRemaining = String(Math.floor(timeDifference / (1000 * 60 * 60))).padStart(2, '0');
  const minutesRemaining = String(Math.floor((timeDifference / (1000 * 60)) % 60)).padStart(2, '0');
  const secondsRemaining = String(Math.floor((timeDifference / 1000) % 60)).padStart(2, '0');

  return {
    hours: hoursRemaining,
    minutes: minutesRemaining,
    seconds: secondsRemaining,
    isInactive: false,
  };
};
const MarketTime = () => {
  const mdUp = useResponsive('up', 'md');

  const theme = useTheme()
  const spanStyle = {
    backgroundColor: theme.palette.background.default,
    fontWeight: 600,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    padding: 7, minWidth: 20,
  };
  const [remainingTime, setRemainingTime] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      const timeRemaining = calculateTimeRemaining();
      setRemainingTime(timeRemaining);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    mdUp ?
      <div
      // sx={{
      //     m: 2, p: 1.75, mr: 0, ml: 0,display:"flex",alignContent:"center"
      //     // backgroundColor:"#f9fafbcc"
      // }}
      >
        <MyCard>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="body2" textAlign="start" sx={{ p: 1.5 }}>
              MARKET OPEN{' '}
              <span style={spanStyle}>
                02:30 AM
              </span>{' '}
              UAE TIME
            </Typography>
            <Typography variant="body2" textAlign="end" sx={{ p: 1.5 }}>
              TO{' '}
              <span style={spanStyle}       >
                01:30 AM
              </span>{' '}
              MONDAY TO FRIDAY
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {remainingTime.isInactive ? <Typography variant="h5" color={"red"} textAlign="center" sx={{ p: 1 }}>MARKET CLOSED</Typography> :
              <Typography variant="body1" textAlign="start" sx={{ p: 1 }}>
                TIME TO CLOSE{' '}
                <span style={spanStyle}     >
                  {remainingTime.hours}
                </span>{' '}
                HRS{' '}
                <span
                  style={spanStyle}
                >
                  {remainingTime.minutes}
                </span>{' '}
                MTS{' '}
                <span style={spanStyle} >
                  {remainingTime.seconds}
                </span>{' '}
                SEC
              </Typography>
            }
          </div>
        </MyCard>
      </div>
      :

      // sx={{
      //     m: 2, p: 1.75, mr: 0, ml: 0,display:"flex",alignContent:"center"
      //     // backgroundColor:"#f9fafbcc"
      // }}
      (<>
        <MyCard textAlign={"center"}>

          <Typography variant="body2" textAlign="center" sx={{ p: 1 }}>
            MARKET OPEN{' '}
            <span style={spanStyle}>
              02:30 AM
            </span>{' '}
            UAE TIME
          </Typography>

          <Typography variant="body2" textAlign="center" sx={{ p: 1 }}>
            TO{' '}
            <span style={spanStyle}       >
              01:30 AM
            </span>{' '}
            MONDAY TO FRIDAY
          </Typography>

          <div  >
            {remainingTime.isInactive ?
              <Typography variant="h5" color={"red"} textAlign="center" sx={{ p: 1 }}>MARKET CLOSED</Typography> :
              <>
                <Typography variant="body2" textAlign="center" sx={{ p: 1 }}>
                  TIME TO CLOSE{' '}
                </Typography>
                <Typography variant="body2" textAlign="center" sx={{ p: 1 }}>
                  <span style={spanStyle}     >
                    {remainingTime.hours}
                  </span>{' '}
                  HRS{' '}
                  <span
                    style={spanStyle}
                  >
                    {remainingTime.minutes}
                  </span>{' '}
                  MTS{' '}
                  <span style={spanStyle} >
                    {remainingTime.seconds}
                  </span>{' '}
                  SEC
                </Typography></>
            }
          </div>
        </MyCard>
      </>)

  );
};

export default MarketTime;
