// store.js
import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './dataSlice';
import newsReducer from './newsSlice';
import sentReducer from './sentSlice';

const store = configureStore({
  reducer: {
    data: dataReducer,
    news: newsReducer,
    sent: sentReducer
  },
});

export default store;
