import React from 'react';
import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const MyCard = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.secondary,
        // borderRadius: '10px',
        boxShadow: '3px 4px 12px 0px rgba(0, 0, 0, 0.1)',
        padding: 1,
      }}
      {...props}
    >
      {children}
    </Card>
  );
};

export default MyCard;
