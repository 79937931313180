import { Helmet } from 'react-helmet-async';
import { styled, useTheme } from '@mui/material/styles';

import { Typography, Grid, Card, Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
 
import { NavLink } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import Logo from '../components/logo'; 
import MyContainer from '../components/MyContainer';  
import SpotRateSide from '../components/SpotRateSide';
import RiskGuide from '../components/RiskGuide';
import MyCard from '../components/MyCard';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width: '100%',
    maxHeight: '100vh',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    width: '100%',
  },
}));

export default function Risk() {
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();
 
  return (
    <>
      <Helmet>
        <title>Loona Gold </title>
      </Helmet>
      <MyContainer>
        <Grid container spacing={2.5}> {/* Removed spacing */}
          {/* headcontent */}
          <Grid item xs={12} textAlign={"center"}>
            <Box sx={{
              float: "left",
              width: "100px"
            }
            }>
              <NavLink to="/" >
                <MyCard >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowBackIcon sx={{ height: '20px' }} />
                    <Typography variant="button" sx={{ marginLeft: '8px' }}>
                      BACK
                    </Typography>
                  </Box>
                </MyCard>
              </NavLink></Box>
            <Logo />
          </Grid>

          {/* leftcontent */}
          <Grid item xs={12} md={2} textAlign={"center"}>
            <SpotRateSide type="GOLD" />
          </Grid> 
           <Grid item xs={12} md={8} textAlign={"center"}>
           <RiskGuide/>
          </Grid>
          <Grid item xs={12} md={2} textAlign={"center"}>
            <SpotRateSide type="SILVER" />
          </Grid>
 
        </Grid>
 

      </MyContainer>
    </>
  );
}
