// api.js
import axios from 'axios';
import { setData } from './dataSlice';
import { setNews } from './newsSlice';
import { setSent } from './sentSlice'
import { fetchDataFromFirestore, updateDocumentInFirestore } from './FirebaseService';

export const fetchData = async (dispatch) => {
  try {
    const response = await axios.get('https://loonagold.com/mt5request.php');
    dispatch(setData(response.data.answer));

    // await fetchDataAndUpdateFirestore(response.data.answer[0].Bid);
    // console.log("fetchdataanswer", response.data.answer[0].Bid);
  } catch (err) {
    // Handle error
    console.error('Error fetching data:', err);
  }
};
export const fetchDataforNotify = async (dispatch) => {
  try {
    const response = await axios.get('https://loonagold.com/mt5request.php');
    await fetchDataAndUpdateFirestore(response.data.answer[0].Bid);
    console.log("fetchdataanswer", response.data.answer[0].Bid);
  } catch (err) {
    // Handle error
    console.error('Error fetching data:', err);
  }
};
export const fetchSentData = async (dispatch) => {
  try {
    const response = await axios.get('https://loonagold.com/mt5request.php');
    dispatch(setSent(response.data.answer));
    // console.log("fetchsentanswer", response.data.answer);
  } catch (err) {
    // Handle error
    console.error('Error fetching Sentidata:', err);
  }
};

export const fetchNewsData = async (dispatch) => {
  try {
    const response = await axios.get('https://loonagold.com/newsApi.php');
    dispatch(setNews(response.data));
  } catch (err) {
    // Handle error
    console.error('Error fetching data newws:', err);
  }
};

export const fetchDataAndUpdateFirestore = async (realTimePrice) => {
  try {
    const firestoreData = await fetchDataFromFirestore();

    await Promise.all(
      firestoreData.map(async (itemDoc) => {

        itemDoc.alert.forEach(async (item, index) => {
          await comparePrices(item, realTimePrice);
        });
      })
    );
  } catch (error) {
    console.error('Error updating data:', error);
  }
};

const comparePrices = async (item, realTimePrice) => {


  const { setPriceValue, currentPrice, fcmToken } = item;
  if (
    currentPrice !== null &&
    setPriceValue !== null &&
    setPriceValue === realTimePrice
  ) {
    await sendNotification(setPriceValue, fcmToken);
    await updateDocumentInFirestore(item);
  }
  else if (
    currentPrice !== null &&
    setPriceValue !== null &&
    currentPrice <= setPriceValue &&
    setPriceValue <= realTimePrice
  ) {
    await sendNotification(setPriceValue, fcmToken);
    await updateDocumentInFirestore(item);
  } else if (
    currentPrice >= setPriceValue &&
    setPriceValue >= realTimePrice
  ) {
    await sendNotification(setPriceValue, fcmToken);
    await updateDocumentInFirestore(item);
  }
};


const sendNotification = async (price, token) => {
  const title = "Gold Price Alert";
  const body = `Current Price is ${price}`;

  const apiKey = 'AAAAL_NbjQA:APA91bGAhPB3AJxAsLfNSkPULlBJj60TORmY2--N2YhZf1rx6ujQbfS6B-qaD0JYA13XejhHQQCM3y0C597INARZGHC4cHk_yUQahvg4NWr3V2kZic471MSCR4HqH1TfWssuyK5v1L0P';
  const apiUrl = `https://fcm.googleapis.com/fcm/send`;

  const payload = {
    to: token,
    notification: {
      title,
      body,
      sound: 'bing.mp3',
      icon: "ic_notification_icon",
      vibrate: true,
      color: "#101113",
      channelId: "PriceAlertB",
      android_channel_id: "PriceAlertB",
      // smallIcon: "ic_notification_icon",  
      // color: "black", 
      //  priority: 'ma',
      // importance: "Importance.HIGH", 
    },
    data: {
      // Add any additional data you want to send
      price,
    },
  };

  // await messaging().send(payload);
  console.log("SENDNTFY");
  try {
    const response = await axios.post(apiUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `key=${apiKey}`,
      },
    });

    console.log('Notification sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending notification:', error.response.data);
  }
};
