import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import TradeSentiments from './TradeSentiments';
import { fetchSentData } from '../api';
import { selectSent } from '../sentSlice';
import useResponsive from '../hooks/useResponsive';

const Sentimentcharts = ({ type }) => {
    const dispatch = useDispatch();
    const sent = useSelector(selectSent);
    const mdUp = useResponsive('up', 'md');
    const fetchSentDataMemoized = useCallback(() => {
        fetchSentData(dispatch);
    }, [dispatch]);

    useEffect(() => {
        fetchSentDataMemoized();
        const sentimentChartsIntervalId = setInterval(() => {
            fetchSentDataMemoized();
        }, 60000); // 30 seconds
        return () => {
            clearInterval(sentimentChartsIntervalId);
        };
    }, [fetchSentDataMemoized]);

    const [chartData, setChartData] = useState({
        goldHigh: undefined,
        goldLow: undefined,
        goldOpen: undefined,
        goldAsk: undefined,
        silverHigh: undefined,
        silverLow: undefined,
        silverOpen: undefined,
        silverAsk: undefined,
    });

    useEffect(() => {
        if (sent) {
            setChartData({
                goldHigh: sent[0].AskHigh > sent[0].BidHigh ? sent[0].AskHigh : sent[0].BidHigh,
                goldLow: sent[0].AskLow < sent[0].BidLow ? sent[0].AskLow : sent[0].BidLow,
                goldOpen: sent[0].PriceOpen,
                goldAsk: sent[0].Ask,
                // silverHigh: sent[1].AskHigh,
                // silverLow: sent[1].AskLow, 
                silverHigh: sent[1].AskHigh > sent[1].BidHigh ? sent[1].AskHigh : sent[1].BidHigh,
                silverLow: sent[1].AskLow < sent[1].BidLow ? sent[1].AskLow : sent[1].BidLow,
                silverOpen: sent[1].PriceOpen,
                silverAsk: sent[1].Ask,
            });
        }
    }, [sent]);
    let Goldbuypercentage = Math.round(((chartData.goldHigh - chartData.goldOpen) / (chartData.goldHigh - chartData.goldLow)) * 100);
    Goldbuypercentage = Goldbuypercentage > 100 ? Math.round(((chartData.goldHigh - chartData.goldLow) / (chartData.goldHigh - chartData.goldOpen)) * 100)
        : Math.max(2, Math.min(Goldbuypercentage, 100));

    let Silverbuypercentage = Math.round(((chartData.silverHigh - chartData.silverOpen) / (chartData.silverHigh - chartData.silverLow)) * 100);
    Silverbuypercentage = Silverbuypercentage > 100 ? Math.round(((chartData.silverHigh - chartData.silverLow) / (chartData.silverHigh - chartData.silverOpen)) * 100)
        : Math.max(2, Math.min(Silverbuypercentage, 100));

    const Goldsellpercentage = 100 - Goldbuypercentage;

    const Silversellpercentage = 100 - Silverbuypercentage;

    // 222222222
    // let Goldbuypercentage = Math.round(((chartData.goldHigh - chartData.goldOpen) / (chartData.goldHigh - chartData.goldLow)) * 100);
    // Goldbuypercentage = Goldbuypercentage > 100 ? Math.round(((chartData.goldHigh - chartData.goldLow) / (chartData.goldHigh - chartData.goldOpen)) * 100)
    //     : Goldbuypercentage;

    // let Silverbuypercentage = Math.round(((chartData.silverHigh - chartData.silverOpen) / (chartData.silverHigh - chartData.silverLow)) * 100);
    // Silverbuypercentage = Silverbuypercentage > 100 ? Math.round(((chartData.silverHigh - chartData.silverLow) / (chartData.silverHigh - chartData.silverOpen)) * 100)
    //     : Silverbuypercentage;

    // const Goldsellpercentage = 100 - Goldbuypercentage;

    // const Silversellpercentage = 100 - Silverbuypercentage;

    // 111111111111
    // // Make sure that the sum of buy and sell percentages is 100
    // const totalPercentage = Goldbuypercentage + Goldsellpercentage;
    // if (totalPercentage !== 100) {
    //     // Adjust Goldsellpercentage to make the total 100%
    //     Goldsellpercentage -= (totalPercentage - 100);
    //     // Goldbuypercentage = 100 - Goldsellpercentage;
    // }


    const goldpriceChangePercentageAsk = ((chartData.goldAsk - chartData.goldOpen) / chartData.goldOpen) * 100;
    const SilverpriceChangePercentageAsk = ((chartData.silverAsk - chartData.silverOpen) / chartData.silverOpen) * 100;

    return (
        <>
            <TradeSentiments type={2}
                percent={sent ? (type === "GOLD" ? goldpriceChangePercentageAsk.toFixed(3) : SilverpriceChangePercentageAsk.toFixed(3)) : ""}
                buy={type === "GOLD" ? Goldbuypercentage : Silverbuypercentage} sell={type === "GOLD" ? Goldsellpercentage : Silversellpercentage} />
            <Box style={{ display: 'flex', borderRadius: 3, background: "#22282C", justifyContent: 'space-between', alignItems: 'center' }}>
                <Box
                    sx={{
                        width: '90px',
                        height: '30px',
                        borderRadius: 3,
                        background: '#1ED270',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: "15px"
                    }}
                >
                    <Typography variant="button" sx={{ color: '#FFF', fontSize: (mdUp ? "11px" :"10px") }}>
                        BUYERS  {sent ? (type === "GOLD" ? Goldbuypercentage : Silverbuypercentage) : 0}%
                    </Typography>
                </Box>
                <img src="/assets/loona-fav.png" alt="Loona Fav" style={{ width: '25px', height: '25px' }} />
                <Box
                    sx={{
                        width: '90px',
                        height: '30px',
                        borderRadius: 3,
                        background: '#F44336',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: "15px"
                    }}
                >
                    <Typography variant="button" sx={{ color: '#FFF', fontSize:(mdUp ? "11px" :"10px")}}>
                        SELLERS  {sent ? (type === "GOLD" ? Goldsellpercentage : Silversellpercentage) : 0}%
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default Sentimentcharts;
