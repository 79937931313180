// dataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const newsSlice = createSlice({
  name: 'news',
  initialState: null,
  reducers: { 
    setNews: (state, action) => {
      return action.payload;
    },
  },
});

export const { setNews} = newsSlice.actions;

// Add this export
export const selectData = (state) => state.news;

export default newsSlice.reducer;
