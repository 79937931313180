import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Typography } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import MyCard from './MyCard';

const DateTimeView = () => {
    const mdUp = useResponsive('up', 'md');
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    // const formattedTime = currentTime.toLocaleTimeString([], {
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     hour12: true,
    // }).replace(/(am|pm)/i, (match) => match.toUpperCase());

    // const twelveHourFormat = formattedTime.replace(/^0/, '12');
    const hours = currentTime.getHours() % 12 || 12;
    const minutes = currentTime.getMinutes();
    const ampm = currentTime.getHours() >= 12 ? 'PM' : 'AM';

    const formattedTime = `${hours < 1 ? '12' : hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

    const formattedDate = currentTime.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });

    return (
        mdUp ? <div
        // sx={{
        //     m: 2, p: 1.75, mr: 0, ml: 0,display:"flex",alignContent:"center"
        //     // backgroundColor:"#f9fafbcc"
        // }}
        >
            <MyCard>
                <Typography variant="h4" textAlign={"center"}
                    sx={{ p: 0 }}>
                    {formattedTime}
                </Typography>
                <Typography variant="body1" textAlign={"center"}
                    sx={{ fontWeight: 500 }}>
                    {`${currentTime.toLocaleDateString('en-US', { weekday: 'long' })}`}
                </Typography>
                <Typography variant="body1" textAlign={"center"}
                    sx={{ fontWeight: 500 }}>
                    {formattedDate.replace(',', '')}
                </Typography>
            </MyCard>
        </div>
            :
            <div>
                <Typography variant="h4" textAlign={"right"}
                    sx={{ pt: 1.5, pb: 0.5 }}>
                    {formattedTime}
                </Typography>
                <Typography textAlign={"right"} variant="h5"
                    sx={{   pt: 0, fontSize: 12 }}>
                    {`${currentTime.toLocaleDateString('en-US', { weekday: 'long' })}, ${formattedDate}`}
                </Typography>
            </div>

    );
};

export default DateTimeView;
