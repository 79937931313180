import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Marquee from 'react-double-marquee';
import axios from 'axios';
// import { parseString } from 'xml2js';
import { parse } from 'rss-to-json';
import mockData from '../_mock/mockData';
import './GoldPriceNewsTicker.css'; // Make sure to create a CSS file for styling
import MyCard from './MyCard';
import { selectData } from '../newsSlice';
import { fetchNewsData } from '../api';
import useResponsive from '../hooks/useResponsive';

const GoldPriceNewsTicker = () => {
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const theme = useTheme()
  const mockNewsData = mockData.mockNewsData;
  const [items, setItems] = useState([]);
  const mdUp = useResponsive('up', 'md');
  const dispatch = useDispatch();
  const data = useSelector(selectData);


  useEffect(() => {
    // Fetch data initially
    fetchNewsData(dispatch);

    // Set up interval to fetch data every 10 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchNewsData(dispatch);

    }, 30 * 60 * 1000); // 10 seconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [dispatch]);
  // if (!data) {
  //   return <div>Loading...</div>;
  // }
  const decodeEntities = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };
  return (
    <Card sx={{ background: theme.palette.background.default, display: 'flex', justifyContent: 'space-between',  alignContent: 'center' }}>
      <Typography
        textAlign={'right'}
        sx={mdUp ?{
          background: theme.palette.background.third,
          color: 'white',
          fontWeight: 900,
          p: 1.5, pl: 3, pr: 3,
          fontStyle: 'italic'
        }:{
          background: theme.palette.background.third,
          color: 'white',
          fontWeight: 600,p:1,paddingTop:1.25,
           pl: 1.25, pr: 1.25,fontSize:13,
          fontStyle: 'italic'
        }}
      >
        NEWS
      </Typography>

      <CardContent className="marquee-container" style={mdUp ?{
        padding: 2, paddingTop: 12, fontStyle: 'italic',
        background: theme.palette.background.secondary,
      }:{
        padding: 1, paddingTop:10, fontStyle: 'italic',
        background: theme.palette.background.secondary,fontSize:13
      }}>
        {(!data) ? <div>Loading...</div> : 
        <Marquee direction="left"  >
          {data.map((item, index) => (
            <span key={index}>{`${decodeEntities(item.title)} | `}</span>
          ))}
        </Marquee>}
      </CardContent>
    </Card>
  );
};

export default GoldPriceNewsTicker;
