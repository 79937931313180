// sentSlice.js
import { createSlice } from '@reduxjs/toolkit';

const sentSlice = createSlice({
  name: 'sent',
  initialState: null,
  reducers: {
    setSent: (state, action) => {
      return action.payload;
    }, 
  },
});

export const { setSent } = sentSlice.actions;

// Add this export
export const selectSent = (state) => state.sent;

export default sentSlice.reducer;
