import { Helmet } from 'react-helmet-async';
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Grid, Card, Box, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import SpotRateComponent from '../components/SpotRateComponent ';
import GoldPriceNewsTicker from '../components/GoldPriceNewsTicker ';
import FloatingButton from '../components/FloatingButton';
import useResponsive from '../hooks/useResponsive';
import Logo from '../components/logo';
import DateTimeView from '../components/DateTimeView';
import MyContainer from '../components/MyContainer';
import TradingViewWidget from '../components/TradingViewWidget';
import MarketTime from '../components/MarketTime';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width: '100%',
    maxHeight: '100vh',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    width: '100%',
  },
}));

export default function HomePage() {
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>Loona Gold </title>
      </Helmet>

      <MyContainer>
        <Grid container spacing={2} > {/* Removed spacing */}
          {!mdUp ?
            <>
              <Grid item xs={6} textAlign={"left"}>
                <Logo sx={{width:"145px",marginTop:"7px"}}/>
              </Grid>
              <Grid item xs={6} textAlign={"right"} gutterBottom>
                <DateTimeView />
              </Grid>
              <Grid item xs={12} gutterBottom>
                <MarketTime />
              </Grid>
              <Grid item xs={12}>
                <SpotRateComponent type="GOLD" />
              </Grid>
              <Grid item xs={12}>
                <SpotRateComponent type="SILVER" />
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ background: theme.palette.background.default }
                }>
                  <TradingViewWidget />
                </Card>
              </Grid>
              <Grid item xs={12} gutterBottom>
                <GoldPriceNewsTicker />
              </Grid>
            </>
            :
            <>
              {/* leftcontent */}
              <Grid item xs={12} md={4} lg={3} xl={3} textAlign={"center"}>
                <Grid container spacing={1}>
                  <Grid item xs={12} gutterBottom>
                    <Logo />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom> SPOT RATE </Typography>
                    <SpotRateComponent type="GOLD" />

                  </Grid>
                  <Grid item xs={12}>
                    <SpotRateComponent type="SILVER" />
                  </Grid>
                </Grid>
              </Grid>


              {/* rightcontent */}
              <Grid item xs={12} md={8} lg={9} xl={9}>
                <Grid container spacing={2} mt={3}>
 <Grid item xs={12} gutterBottom>
                    <GoldPriceNewsTicker />
                  </Grid>
                  <Grid item xs={12} md={8} lg={9} xl={9} gutterBottom>

                    <MarketTime />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} xl={3} gutterBottom>
                    <DateTimeView />
                  </Grid>
                  <Grid item xs={12}>
                    <Card sx={{ background: theme.palette.background.default }
                    }>
                      <TradingViewWidget />
                    </Card>
                  </Grid>
                 
                  {/* <Grid item xs={12} md={4} >
                <NavLink to="/limit">
                  <Button size='large' fullWidth variant="contained" color="warning">
                    Limit Calculator
                  </Button>
                </NavLink>
              </Grid>
              <Grid item xs={12} md={4} >
                <NavLink to="/profit">
                  <Button size='large' fullWidth variant="contained" color="warning">
                    Profit Calculator
                  </Button>
                </NavLink>
              </Grid>
              <Grid item xs={12} md={4} >
                <NavLink to="/risk">
                  <Button size='large' fullWidth variant="contained" color="warning">
                    Risk Guide
                  </Button>
                </NavLink>
              </Grid> */}
                </Grid>
              </Grid>
            </>
            }
        </Grid>




        {/* <FloatingButton /> */}

      </MyContainer>
    </>
  );
}
